/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem, shade} from 'polished';
import {
    breakpoints,
    colors,
    fontFamilies,
    spacing,
    themes,
    transitions
} from '../../globals/variables';

export const button = css`
    border-radius: 0;
    // border: 2px solid currentColor;
    cursor: pointer;
    display: inline-block;
    font-family: ${fontFamilies.bold};
    font-size: 1.2rem;
    line-height: 1.2;
    overflow: hidden;
    padding: ${rem(spacing.s * 1.5)};
    padding-right: ${rem(spacing.s * 6)};
    position: relative;
    text-align: center;

    &:hover,
    &:active {
        transform: translateY(-1px);
    }

    @media (max-width: ${rem(breakpoints.mobile)}) {
        display: block;

        & + & {
            margin-top: ${rem(spacing.s)};
        }
    }
`;

export const buttonTheme = (theme: string) => css`
    background-color: ${themes[theme].background};
    border-color: ${themes[theme].background};
    color: ${themes[theme].text};

    &:hover,
    &:focus {
        background-color: ${shade(0.05, themes[theme].background)};
        color: ${themes[theme].text};
    }
`;

export const buttonBlock = css`
    display: block;
    text-align: left;
    width: 100%;
`;

export const buttonGhost = css`
    &:not(:hover):not(:active) {
        background: transparent;
        border-color: currentColor;
        color: currentColor;
    }
`;

export const Icon = styled.svg`
    fill: currentColor;
    position: absolute;
    right: ${rem(spacing.m)};
    transition: ${transitions.default};
    transform: translateY(-50%);
    top: 50%;

    a:hover &,
    a:active &,
    button:hover &,
    button:active & {
        transform: translateY(-50%) scale(1.15);
    }
`;
