/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import * as S from './styles';

type Props = {
    children: React.Node,
    theme?: string
};

const Theme = ({children, theme}: Props) => <S.Container theme={theme}>{children}</S.Container>;

export default Theme;
