/**
 * @prettier
 * @flow
 */
import React from 'react';
import {Link} from 'gatsby';
import {getLinkProps} from '../../globals/functions';
import * as S from './styles';

type Props = {
    isActive: boolean,
    links: Array<Object>
};

const OverlayNavigation = ({isActive, links}: Props) => (
    <S.Container isActive={isActive} className={isActive && 'is-active'}>
        <S.Wrapper>
            {links.map(link => (
                <Link to={link.to} key={link.to} css={S.link} getProps={getLinkProps}>
                    <S.TextWrapper>{link.label}</S.TextWrapper>
                </Link>
            ))}
        </S.Wrapper>
    </S.Container>
);

export default OverlayNavigation;
