/**
 * @prettier
 * @/flow
 */
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import last from 'lodash/last';
import map from 'lodash/map';

const getRootElementFontSize = () => {
    let size = 16;

    if (typeof window !== 'undefined' && document.documentElement) {
        size = parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    return size;
};

export const rem = (value: number) => `${(value / getRootElementFontSize()).toFixed(4)}rem`;

export const photoURL = (filename: string = '') =>
    filename.includes('http') ? filename : `/static/images/${filename}`;

export const getURLFromType = () => {};

export const getStraplineFromMarkdown = (content: string = '') => {
    let formattedContent;

    formattedContent = content.replace(new RegExp('__', 'g'), '');
    formattedContent = formattedContent.replace(new RegExp(/\*/, 'g'), '');
    formattedContent = formattedContent.split('\n');

    return formattedContent[0];
};

export const getLinkProps = (props: Object) => {
    if (props.isPartiallyCurrent) {
        return {
            'data-active': true
        };
    }
};

export const responsiveSpacing = (unit: number = 0, calculation: string = '+') =>
    `calc(${rem(unit)} ${calculation} var(--responsive-spacing))`;

export const getKeyValues = (resources: Array<Object> = []) => {
    return fromPairs(
        map(resources, r => {
            const key = last(get(r, 'key', '').split('.'));
            const value = get(r, 'value.value');

            return [key, value];
        })
    );
};

export const getResources = (data: Object, title: string, property: string = 'resources') => {
    const resources = find(get(data, property), {title});

    if (resources) {
        return getKeyValues(get(resources, 'references'));
    }
};
