/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Main = styled.main`
    flex-grow: 1;
`;
