/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';
import {themes} from '../../globals/variables';

export const Container = styled.div`
    ${props => props.theme && `background-color: ${themes[props.theme].background}`};
    ${props => props.theme && `color: ${themes[props.theme].text}`};
`;
