/**
 * @prettier
 */
import styled from '@emotion/styled';
import {colors, spacing, transitions} from '../../globals/variables';

const SIZE = 40;
const LINE_HEIGHT = 4;

export const Button = styled.button`
    cursor: pointer;
    height: ${SIZE * 0.5625}px;
    margin-right: ${spacing.s}px;
    margin-top: ${spacing.s}px;
    position: relative;
    transform: rotate(0deg);
    transition: ${transitions.default};
    width: ${SIZE}px;
`;

export const Line = styled.span`
    background: ${colors.white};
    border-radius: 2px;
    display: block;
    height: ${LINE_HEIGHT}px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: ${transitions.default};
    width: 100%;

    .is-active & {
        background: ${colors.white};
    }

    &:nth-of-type(1) {
        top: 0px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
        top: calc(50% - ${LINE_HEIGHT / 2}px);
    }

    &:nth-of-type(4) {
        top: calc(100% - ${LINE_HEIGHT}px);
    }

    .is-active > &:nth-of-type(1) {
        top: ${LINE_HEIGHT * 2}px;
        width: 0%;
        left: 50%;
    }

    .is-active > &:nth-of-type(2) {
        transform: rotate(45deg);
    }

    .is-active > &:nth-of-type(3) {
        transform: rotate(-45deg);
    }

    .is-active > &:nth-of-type(4) {
        left: 50%;
        top: ${LINE_HEIGHT * 2}px;
        width: 0%;
    }
`;
