/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {cover, rem} from 'polished';
import {colors, spacing, transitions} from '../../globals/variables';

export const Container = styled.div`
    background-color: ${colors.orange};
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.12);
    color: ${colors.white};
    padding-bottom: ${rem(spacing.l)};
    padding-top: calc(${rem(70)} + 6vmax);
    position: relative;

    &::before {
        ${cover()}
        background: linear-gradient(305deg, ${colors.orangeDark} 40%, ${colors.orange} 40%);
        content: '';
        transition: ${transitions.default};
    }
`;

export const heading = css`
    margin-bottom: 0;
    max-width: 90%;
`;
