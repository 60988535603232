/**
 * @prettier
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {between, rem} from 'polished';
import {
    breakpoints,
    colors,
    fontFamilies,
    fontSizes,
    spacing,
    transitions
} from '../../globals/variables';

export const Container = styled.div`
    background-color: ${colors.primary};
    bottom: 0;
    color: ${colors.white};
    left: 0;
    right: 0;
    opacity: 0;
    overflow: ;
    position: fixed;
    transition: ${transitions.default};
    visibility: hidden;
    top: 0;

    ${props =>
        props.isActive &&
        `
        opacity: 1;
        visibility: visible;
        `};

    @media (min-width: ${rem(breakpoints.mobile)}) {
        display: none;
    }
`;

export const Wrapper = styled.nav`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    padding: ${rem(spacing.m)};
    transition: ${transitions.slow};
    transform: translateY(${spacing.m}px);
    width: 100%;

    .is-active > & {
        transform: translateY(0);
    }
`;

export const link = css`
    color: ${colors.white};
    display: block;
    font-family: ${fontFamilies.bold};
    font-size: ${between(`${fontSizes.lead + 15}px`, `${fontSizes.lead + 30}px`)};
    padding: ${rem(spacing.xs)} 0;
    text-align: center;
`;

export const TextWrapper = styled.span`
    position: relative;

    [data-active] > &::after {
        background-color: currentColor;
        bottom: ${rem(-4)};
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        transition: ${transitions.default};
        width: 100%;
    }
`;
