/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import AnimateWhenVisible from '../AnimateWhenVisible';
import Container from '../Container';
import * as S from './styles';
import * as UI from '../UI/styles';

type Props = {
    children: React.Node,
    text?: string,
    title: string
};

const HeroBanner = ({children, text, title}: Props) => (
    <S.Container>
        <Container verticalPadding={false}>
            {title && (
                <AnimateWhenVisible>
                    <UI.Heading1 css={S.heading}>{title}</UI.Heading1>
                </AnimateWhenVisible>
            )}

            {title && text && <UI.Spacer />}
            {text && <AnimateWhenVisible delay={0.5}>{text}</AnimateWhenVisible>}

            {title && children && <UI.ResponsiveSpacer />}
            {children}
        </Container>
    </S.Container>
);

export default HeroBanner;
