/**
 * @prettier
 * @flow
 */
import React, {Fragment, useState} from 'react';
import {StaticQuery, graphql} from 'gatsby';
import CTAButton from '../CTAButton';
import NewsletterForm from '../NewsletterForm';
import * as S from './styles';
import * as UI from '../UI/styles';

const NewsletterHero = () => {
    const [showForm, setShowForm] = useState(false);

    const handleSignUpClick = (event: UIEvent) => {
        event.preventDefault();
        setShowForm(true);
    };

    return (
        <StaticQuery
            query={graphql`
                {
                    content: contentfulNewsletter {
                        title
                        text {
                            text
                        }
                        signUpCtaLabel
                        previewCtaLabel
                        previewCtaPath
                    }
                }
            `}
            render={data => {
                const {content} = data;

                return (
                    <Fragment>
                        <UI.Heading3>{content.title}</UI.Heading3>

                        <UI.LayoutContainer>
                            <UI.LayoutItem sizeAtMobile={6 / 12} sizeAtTablet={7 / 12}>
                                <p>{content.text.text}</p>
                            </UI.LayoutItem>
                            <UI.LayoutItem sizeAtMobile={1 / 12} />
                            <UI.LayoutItem sizeAtMobile={5 / 12} sizeAtTablet={4 / 12}>
                                {!showForm && (
                                    <CTAButton onClick={handleSignUpClick} block theme="light">
                                        {content.signUpCtaLabel}
                                    </CTAButton>
                                )}
                                {showForm && <NewsletterForm />}
                            </UI.LayoutItem>
                        </UI.LayoutContainer>
                    </Fragment>
                );
            }}
        />
    );
};

export default NewsletterHero;
