/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import Link from '../Link';
import * as S from './styles';

type Props = {
    children: React.Node,
    block?: boolean,
    ghost?: boolean,
    hasIcon?: boolean,
    small?: boolean,
    to?: string,
    theme?: string,
    type?: string
};

const CTAButton = ({
    children,
    hasIcon = true,
    to = '',
    type = 'button',
    block = false,
    ghost = false,
    theme = 'primary',
    ...props
}: Props) => {
    const classes = [
        S.button,
        block && S.buttonBlock,
        ghost && S.buttonGhost,
        theme && S.buttonTheme(theme)
    ];

    return (
        <Link to={to} css={classes} type={type} {...props}>
            {children}
            {hasIcon && (
                <S.Icon width="29" height="19" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.184 8.878h25.169l-7.82-7.82.836-.838 8.37 8.37.837.837-9.207 9.207-.837-.837 7.736-7.736H0l1.184-1.183z"
                        fillRule="nonzero"
                    />
                </S.Icon>
            )}
        </Link>
    );
};

export default CTAButton;
