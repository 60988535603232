/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';
import {rem} from 'polished';
import {breakpoints, colors, fontFamilies, spacing, themes} from '../../globals/variables';

export const TextInput = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.black};
    display: block;
    font-size: 1rem;
    padding: ${rem(spacing.s * 1.5)};
    width: 100%;

    &:focus,
    &:active {
        border: 1px solid ${colors.orange};
    }
`;

export const TextArea = styled.textarea`
    border: 1px solid ${colors.grey};
    color: ${colors.black};
    display: block;
    font-size: 1rem;
    padding: ${rem(spacing.s * 1.5)};
    width: 100%;

    &:focus,
    &:active {
        border: 1px solid ${colors.orange};
    }
`;

export const ButtonWrapper = styled.div`
    text-align: right;
`;
