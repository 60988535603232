/**
 * @prettier
 * @flow
 */
export const breakpoints = {
    mobileSmall: 540,
    mobile: 741,
    tabletSmall: 769,
    tablet: 1025,
    desktop: 1300
};

export const colors = {
    white: '#fff',
    black: '#2F3745',
    grey: '#9B9B9B',
    greyLight: '#E5E6E8',
    greyDark: '#3D4451',
    orange: '#FAAB1A',
    orangeDark: '#FA9F1B',

    primary: '#FA9F1B',
    secondary: '#9B9B9B',
    tertiary: '#979797'
};

export const themes = {
    primary: {
        background: colors.orange,
        text: colors.white
    },
    secondary: {
        background: colors.greyDark,
        text: colors.white
    },
    light: {
        accent: colors.orangeDark,
        background: colors.white,
        text: colors.black
    },
    asidePrimary: {
        accent: colors.greyLight,
        background: colors.white,
        border: colors.greyLight,
        text: colors.black
    },
    asideSecondary: {
        accent: colors.orangeDark,
        background: colors.white,
        border: colors.orange,
        text: colors.black
    }
};

export const fontFamilies = {
    default: "'Chakra Petch - Regular', Arial",
    bold: "'Chakra Petch - Medium', Arial",
    italic: "'Chakra Petch - Italic', Arial",
    heading: "'Chakra Petch - Medium', Arial"
};

export const fontSizes = {
    default: 18,
    lead: 22,
    h1: 60,
    h2: 48,
    h3: 33,
    h4: 27,
    h5: 16,
    h6: 12
};

export const radius = 0;

export const spacing = {
    xs: 6,
    s: 12,
    m: 24,
    l: 48,
    xl: 72
};

export const transitions = {
    default: 'all .2s ease-in-out',
    slow: 'all .3s ease-out',
    bezier: 'all .25s cubic-bezier(.23,1,.32,1)'
};
