/**
 * @prettier
 * @flow
 */
import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import get from 'lodash/get';
import find from 'lodash/find';
import Container from '../Container';
import * as S from './styles';

const SiteFooter = () => (
    <StaticQuery
        query={graphql`
            query {
                microcopy: contentfulResourceSet(title: {eq: "Site microcopy"}) {
                    references {
                        key
                        value {
                            value
                        }
                    }
                }
            }
        `}
        render={data => {
            const copy = find(get(data, 'microcopy.references'), {key: 'site.footer.text'});

            if (copy && copy.value) {
                return (
                    <S.Footer>
                        <Container verticalPadding={false}>{copy.value.value}</Container>
                    </S.Footer>
                );
            }
        }}
    />
);

export default SiteFooter;
