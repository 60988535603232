/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';
import {transitions} from '../../globals/variables';

export const Container = styled.div`
    transition: ${transitions.bezier};
    transition-duration: 1s;
`;
