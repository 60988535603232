/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {spacing} from '../../globals/variables';

export const CONTAINER_WIDTH = 1350;

export const containerCSS = props => css`
    box-sizing: border-box;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: ${responsiveSpacing(spacing.m)};
    position: relative;
    max-width: ${rem(CONTAINER_WIDTH)};
    width: 100%;

    ${props.verticalPadding === false && `padding-top: 0; padding-bottom: 0;`}
`;

export const Container = styled.div`
    ${containerCSS};
`;
