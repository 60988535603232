/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {colors, spacing} from '../../globals/variables';

export const Label = styled.span`
    color: ${colors.grey};
    font-size: ${rem(15)};
    letter-spacing: ${rem(2)};
    text-transform: uppercase;
`;

export const ListContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        margin-bottom: ${responsiveSpacing(spacing.m)};
    }
`;

export const heading = css`
    margin-bottom: ${rem(spacing.xs)};
`;
