/**
 * @prettier
 */
import styled from '@emotion/styled';
import {rem} from 'polished';
import {breakpoints, colors, spacing} from '../../globals/variables';

export const Footer = styled.footer`
    background-color: ${colors.black};
    color: ${colors.grey};
    font-size: ${rem(12)};
    line-height: 1.4;
    padding: ${rem(spacing.m)} 0;
    position: relative;

    &::before {
        background: linear-gradient(330deg, ${colors.black} 40%, rgba(0, 0, 0, 0) 40%);
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: -6px;
        width: 100%;
        z-index: 0;
    }

    @media (min-width: ${rem(breakpoints.mobile)}) {
        text-align: center;
    }
`;
