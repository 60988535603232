/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import {Link} from 'gatsby';

type Props = {
    children?: React.Node,
    to: string,
    type?: string
};

const LinkType = ({to = '', children, type, ...props}: Props) => {
    if (to.includes('http')) {
        return (
            <a href={to} target="noopener" {...props}>
                {children}
            </a>
        );
    } else if (to) {
        return (
            <Link to={to} {...props}>
                {children}
            </Link>
        );
    } else {
        return (
            <button type={type} {...props}>
                {children}
            </button>
        );
    }
};

export default LinkType;
