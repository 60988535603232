/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Container from '../Container';
import NewsletterHero from '../NewsletterHero';
import OverlayNavigation from '../OverlayNavigation';
import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';
import Theme from '../Theme';
import * as UI from '../UI/styles';
import * as S from './styles';

type Props = {children: React.Node};

const Page = ({children}: Props) => {
    const [isOverlayNavActive, setOverlayNavActive] = React.useState(false);

    const handleHamburgerClick = () => setOverlayNavActive(!isOverlayNavActive);

    return (
        <StaticQuery
            query={graphql`
                query HeadingQuery {
                    site {
                        siteMetadata {
                            navigation {
                                main {
                                    to
                                    label
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <S.PageWrapper>
                    <SiteHeader
                        isHamburgerActive={isOverlayNavActive}
                        handleHamburgerClick={handleHamburgerClick}
                        links={data.site.siteMetadata.navigation.main}
                    />

                    <S.Main>{children}</S.Main>

                    <Theme theme="secondary">
                        <Container>
                            <NewsletterHero />
                        </Container>
                    </Theme>
                    <SiteFooter />
                    <OverlayNavigation
                        isActive={isOverlayNavActive}
                        links={data.site.siteMetadata.navigation.main}
                    />
                </S.PageWrapper>
            )}
        />
    );
};

export default Page;
