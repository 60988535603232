/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import * as S from './styles';

type Props = {
    as?: string,
    children: React.Node,
    verticalPadding?: boolean
};

const Container = ({as = 'div', children, verticalPadding = true}: Props) => (
    <S.Container as={as} verticalPadding={verticalPadding}>
        {children}
    </S.Container>
);

export default Container;
