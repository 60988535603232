/**
 * @prettier
 * @flow
 */
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import {rem} from 'polished';
import {breakpoints, colors, fontFamilies, spacing, transitions} from '../../globals/variables';
import {containerCSS} from '../Container/styles';

export const Header = styled.header`
    padding: ${rem(spacing.m)} 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
`;

export const link = css`
    color: ${colors.white};
    cursor: pointer;
    font-family: ${fontFamilies.bold};
    font-weight: 800;
    letter-spacing: -0.1px;
    position: relative;

    & + & {
        margin-left: ${rem(spacing.l)};
    }

    &::after {
        background-color: currentColor;
        bottom: ${rem(-10)};
        content: '';
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: ${transitions.slow};
        width: 0;
    }

    &[data-active],
    &:hover,
    &:active {
        color: ${colors.white};
    }
    &:hover::after,
    &:active::after,
    &[data-active]::after {
        opacity: 1;
        width: 100%;
    }
`;

export const Container = styled.div`
    ${containerCSS};
    align-items: center;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
`;

export const RightContainer = styled.div`
    flex-grow: 1;
    text-align: right;
`;

export const Logo = styled.img`
    height: ${rem(50)};
    vertical-align: middle;
`;
