/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import {Link} from 'gatsby';
import Hamburger from '../Hamburger';
import {getLinkProps} from '../../globals/functions';
import * as UI from '../UI/styles';
import * as S from './styles';
import logo from '../../images/logo.png';

type Props = {
    isHamburgerActive: boolean,
    handleHamburgerClick: Function,
    links: Array<Object>
};

const SiteHeader = ({isHamburgerActive, handleHamburgerClick, links}: Props) => (
    <S.Header>
        <S.Container>
            <Link to="/" title="Home">
                <S.Logo src={logo} alt="" />
            </Link>
            <S.RightContainer>
                <UI.ShowAt breakpoint="mobile">
                    {links.map(link => (
                        <Link key={link.to} to={link.to} css={S.link} getProps={getLinkProps}>
                            {link.label}
                        </Link>
                    ))}
                </UI.ShowAt>
                <UI.HideAt breakpoint="mobile">
                    <Hamburger handleClick={handleHamburgerClick} isActive={isHamburgerActive} />
                </UI.HideAt>
            </S.RightContainer>
        </S.Container>
    </S.Header>
);

export default SiteHeader;
