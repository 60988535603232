/**
 * @prettier
 * @flow
 */
import React from 'react';
import * as S from './styles';

type Props = {
    handleClick: Function,
    isActive: boolean
};

const Hamburger = ({handleClick, isActive}: Props) => (
    <S.Button
        type="button"
        isActive={isActive}
        onClick={handleClick}
        className={isActive && 'is-active'}
    >
        <S.Line />
        <S.Line />
        <S.Line />
        <S.Line />
    </S.Button>
);

export default Hamburger;
