/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import {spacing} from '../../globals/variables';
import * as S from './styles';

type Props = {
    children: React.Node,
    delay?: number,
    rootMargin?: string,
    style?: Object
};

const AnimateWhenVisible = ({children, delay = 0, rootMargin = '-50px', style = {}}: Props) => {
    const [hasBeenVisible, setHasBeenVisible] = React.useState(false);
    const [styles, setStyles] = React.useState({y: spacing.m, opacity: 0});
    const ref = React.useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setHasBeenVisible(entry.isIntersecting);

                if (!hasBeenVisible && entry.isIntersecting) {
                    setStyles({y: 0, opacity: 1});
                }
            },
            {
                rootMargin
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <S.Container
            ref={ref}
            style={{
                ...style,
                opacity: styles.opacity,
                transform: `translateY(${styles.y}px)`,
                transitionDelay: `${(delay * 20) / 100}s`
            }}
        >
            {children}
        </S.Container>
    );
};

export default AnimateWhenVisible;
